/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <div className="copyright" id="copyright">
          BOLT (HK) © {new Date().getFullYear()}
        </div>
        <div className="copyright" id="sister">
          Sister Company: <a href="https://synque.io/about.html" target="_blank">Synque</a>
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
