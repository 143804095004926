import React from "react";

import { Container, Row, Col } from "reactstrap";

function AboutUsSection() {
  return (
    <>
      <div className="section" id="about-us">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">Who we are?</h2>
              <h5 className="description">
                BOLT (HK) is a digital agency and technical consultancy that
                provides custom solutions tailored to individual clients' needs.
                Our team of professionals combines expert consulting with
                technical knowledge to create innovative, cutting-edge
                solutions. We are committed to delivering the best results,
                tailored to individual customer requirements. Our comprehensive
                and tailored service offers custom solutions for any business
                challenge.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUsSection;
