import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

function ServicesSection() {
  const [iconPills, setIconPills] = React.useState("1");

  return (
    <>
      <div className="section section-tabs" id="services">
        <Container>
          <div className="title">
            <h2 className="title">Services</h2>
          </div>
          <Row>
            <Col className="ml-auto mr-auto" md="12" xl="10">
              <Card>
                <CardHeader>
                  <Nav className="justify-content-center" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={iconPills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("1");
                        }}
                      >
                        <i className="now-ui-icons business_globe"></i>
                        Websites
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("2");
                        }}
                      >
                        <i className="now-ui-icons tech_controller-modern"></i>
                        Apps
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("3");
                        }}
                      >
                        <i className="now-ui-icons tech_laptop"></i>
                        System Architecture
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "4" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("4");
                        }}
                      >
                        <i className="now-ui-icons users_single-02"></i>
                        CTO Service
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    className="text-center"
                    activeTab={"iconPills" + iconPills}
                  >
                    <TabPane tabId="iconPills1">
                      <p>
                        Our website building service provides comprehensive
                        design, development, testing, deployment, and
                        maintenance services tailored to individual needs. Our
                        experienced team is committed to providing the highest
                        quality service and ensuring that the website meets
                        expectations.
                      </p>
                      <img
                        alt="..."
                        src={require("assets/img/web-design.jpg")}
                      ></img>
                    </TabPane>
                    <TabPane tabId="iconPills2">
                      <p>
                        We specialize in developing cross-platform websites and
                        apps that offer a truly seamless user experience. Our
                        development team ensures that our projects provide a
                        uniform level of functionality and compatibility across
                        platforms, allowing users to enjoy their experience
                        without worrying about compatibility issues. We strive
                        to deliver the best quality possible, providing our
                        clients with a smooth and enjoyable user experience that
                        is tailored to their specific needs.
                      </p>
                      <img
                        alt="..."
                        src={require("assets/img/app-design.jpg")}
                      ></img>
                    </TabPane>
                    <TabPane tabId="iconPills3">
                      <p>
                        Proven success in strategy/planning, system integrity,
                        acquisition/deployment, and operations management.
                        Implemented/maintained systems, ensuring
                        security/performance goals met and up-to-date. Led
                        acquisition/deployment of new systems, adhering to
                        budgets and integrating quickly/efficiently. Extensive
                        experience in operations management, providing
                        operational oversight to ensure systems/processes run
                        smoothly and goals achieved.
                        <img
                          alt="..."
                          src={require("assets/img/system-architecture.jpg")}
                        ></img>
                      </p>
                    </TabPane>
                    <TabPane tabId="iconPills4">
                      <p>
                        Our CTO services are designed to help you create a
                        successful development plan, present a compelling
                        project, leverage our team's experience, lower costs
                        through smart decisions, and stay up to date with
                        industry trends. We provide insight into the technical
                        side of development, so clients can make informed
                        decisions. With our expertise, you can rely on us to
                        deliver your project on time, on budget and with maximum
                        value.
                      </p>
                      <img
                        alt="..."
                        src={require("assets/img/cto-service.jpg")}
                      ></img>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ServicesSection;
