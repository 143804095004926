import React from "react";

import { Button, Container, Row, Col } from "reactstrap";

function ContactUsSection() {
  return (
    <>
      <div
        className="section section-download"
        data-background-color="black"
        id="contact-us"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">Question? Talk with us directly.</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-round mr-1"
                color="info"
                href="&#77;&#65;&#73;&#76;&#84;&#111;&#58;&#97;&#100;&#109;&#105;&#110;&#64;&#98;&#111;&#108;&#116;&#104;&#107;&#46;&#99;&#111;&#109;&#63;&#115;&#117;&#98;&#106;&#101;&#99;&#116;&#61;&#87;&#101;&#98;&#37;&#50;&#48;&#69;&#110;&#113;&#117;&#105;&#114;&#121;&#37;&#50;&#48;"
                role="button"
                size="lg"
              >
                &#97;&#100;&#109;&#105;&#110;&#64;&#98;&#111;&#108;&#116;&#104;&#107;&#46;&#99;&#111;&#109;
              </Button>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">Hong Kong Office</h3>
              <h5 className="description">
                22/F, Mira Place Tower A, 132 Nathan Road, Tsim Sha Tsui,
                Kowloon
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContactUsSection;
